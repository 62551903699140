.mailingList {
  background-color: grey;
  color: black;
  display: inline-block;
  /* float: left; */
  font-size: 12pt;
  font-variant-caps: all-small-caps;
  text-align: left;
}

.mailingListTitle {

}

.mailingListTextInputLabel {

}

.mailingListTextInput {

}

.mailingListButtonContainer {

}

.mailingListButton {
  background-color: blue;
  font-size: 12pt;
  font-variant-caps: normal;
  font-weight: bold;
  color: white;
}
