.header {
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerTitleContainer {
  /* border: 5px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
  vertical-align: middle;
  width: fit-content;
}

.headerTitleIcon {
  color:black;
  display: inline-block;
  font-size: 2.5em;
  margin: auto 5px;
  padding: 5px;
}

.headerTitleText {
  /* border: 5px solid black; */
  display: inline-block;
  font-size: 36pt;
  padding: 5px;
  vertical-align: middle;
}

.hexStyle {
  fill: blue;
  stroke-width: 2px;
  stroke: green;
}

.leftIcon {
  float:left;
}

.rightIcon {
  float:right;
}

.menu {
  display: inline-block;
  margin: 0 auto;
}

.menuItem {
  display: inline-block;
  padding: 5px;
}

.menuItem:hover {
  outline: 1px dashed blue;
}

.menuItemContainer {
  display: inline-block;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top:0;
}
