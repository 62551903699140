:root {
  --bgColor: white;
  --bdrRadius: 5pt;
  --col: black;
  --mrgnTop: 3px;
  --padLeft: 2px;
  --padTop: 5px;
  --padBot: 5px;
  --txtAlign: left;
  --transition: 0.2s;
  --boxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.57);
}

.audience {
  background-color: var(--bgColor);
  border-radius: var(--bdrRadius);
  color: var(--col);
  margin-top: var(--mrgnTop);
  padding-left: var(--padLeft);
  padding-top: var(--padTop);
  padding-bottom: var(--padBot);
  text-align: var(--txtAlign);
  transition: var(--transition);
  box-shadow: var(--boxShadow);
}
.audience:hover,.audience:hover i {
  background-color: #dddddd;
  color: blue;
  text-shadow: 0px 0px 0px white;
}
.audience:hover ul {
  text-shadow: 0px 0px 0px white;
}
.audience:hover li {
  text-shadow: 0px 0px 0px white;
}

.audience:hover .arrow {
  border: solid blue;
  border-width: 0 3px 3px 0;
}

.environment {
  background-color: var(--bgColor);
  border-radius: var(--bdrRadius);
  color: var(--col);
  margin-top: var(--mrgnTop);
  padding-left: var(--padLeft);
  padding-top: var(--padTop);
  padding-bottom: var(--padBot);
  text-align: var(--txtAlign);
  transition: var(--transition);
  box-shadow: var(--boxShadow);
}
.environment:hover,.environment:hover i {
  background-color: #dddddd;
  color: blue;
  text-shadow: 0px 0px 0px white;
}
.environment:hover ul {
  text-shadow: 0px 0px 0px white;
}
.environment:hover li {
  text-shadow: 0px 0px 0px white;
}

.environment:hover .arrow {
  border: solid blue;
  border-width: 0 3px 3px 0;
}

.media {
  background-color: var(--bgColor);
  border-radius: var(--bdrRadius);
  color: var(--col);
  margin-top: var(--mrgnTop);
  padding-left: var(--padLeft);
  padding-top: var(--padTop);
  padding-bottom: var(--padBot);
  text-align: var(--txtAlign);
  transition: var(--transition);
  box-shadow: var(--boxShadow);
}
.media:hover,.media:hover i {
  background-color: #dddddd;
  color: blue;
  text-shadow: 0px 0px 0px white;
}
.media:hover ul {
  text-shadow: 0px 0px 0px white;
}
.media:hover li {
  text-shadow: 0px 0px 0px white;
}

.media:hover .arrow {
  border: solid blue;
  border-width: 0 3px 3px 0;
}

.message {
  background-color: var(--bgColor);
  border-radius: var(--bdrRadius);
  color: var(--col);
  margin-top: var(--mrgnTop);
  padding-left: var(--padLeft);
  padding-top: var(--padTop);
  padding-bottom: var(--padBot);
  text-align: var(--txtAlign);
  transition: var(--transition);
  box-shadow: var(--boxShadow);
}
.message:hover,.message:hover i {
  background-color: #dddddd;
  color: blue;
  text-shadow: 0px 0px 0px black;
}
.message:hover ul {
  text-shadow: 0px 0px 0px white;
}
.message:hover li {
  text-shadow: 0px 0px 0px white;
}

.message:hover .arrow {
  border: solid blue;
  border-width: 0 3px 3px 0;
}

.yourself {
  background-color: var(--bgColor);
  border-radius: var(--bdrRadius);
  color: var(--col);
  margin-top: var(--mrgnTop);
  padding-left: var(--padLeft);
  padding-top: var(--padTop);
  padding-bottom: var(--padBot);
  text-align: var(--txtAlign);
  transition: var(--transition);
  box-shadow: var(--boxShadow);
}
.yourself:hover,.yourself:hover i {
  background-color: #dddddd;
  color: blue;
  text-shadow: 0px 0px 0px white;
}
.yourself:hover ul {
  text-shadow: 0px 0px 0px white;
}
.yourself:hover li {
  text-shadow: 0px 0px 0px white;
}

.yourself:hover .arrow {
  border: solid blue;
  border-width: 0 3px 3px 0;
}

.title {
  font-weight: bold;
  text-decoration: underline;
}

.hand {
  cursor: pointer;
}

.body {

}

.listTitle {
  font-style: italic;
}

.listItem {
  font-style: normal;
}

.subListTitle {
  font-style: normal;
}

.subListItem {
  font-style: normal;
}

.hide {
  visibility: hidden;
  height: 0px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.thin {
  border-width: 0 1px 1px 0;
}
