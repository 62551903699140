.header-container {
	display: flex;
	flex-direction: row;
	flex:1;
  justify-content: flex-start;
  border: 0px solid green;
  background-color:#dddddd;
  padding:1%;
  margin: 5px;
  color: rgba(0, 0, 82, 1);
  box-shadow:0px 0px 5px black;
  border-radius: 3px;
  overflow-y: visible;
}
.header-title {
	align-self: center;
	flex:1;
	font-size: 2.5em;
	color: rgba(0, 0, 82, 1);
}
