#root{
  text-align: center;
  /* display: block; */
  background: #fafafa;
  height: 100%;
}
.container{
	display: flex;
	flex-direction: column;
	height: 100%;
}
.main-content{
	flex:1;
	background-color:white;
	overflow-y: scroll
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  height: 100%;
  width: 100%;
}
