.footer {
  /* color: pink; */
  /* background-color: navy; */
  font-weight: bold;
  font-size: 12pt;
  margin: 5em;
  text-align: center;
  /* width: 75%; */
}

.contactInfoContainer{
  display: inline-block;
  /* float: right; */
  text-align: left;
}

.contactInfoTitle{
  /* color: red; */
}

.contactInfoPhone{
  /* color: purple; */
}

.contactInfoEmail{
  /* color: yellow; */
}

.contactInfoFacebook{
  /* color: orange; */
}
