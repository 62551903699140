.main-menu-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #dddddd;
	padding: 3px 1px;
}
.main-menu-button {
	display: block;
	border-top: 1px solid #96d1f8;
	padding: 2.5px 5px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: white;
	font-size: 24px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	text-decoration: none;
	vertical-align: middle;
	margin: 5px;
}
.main-menu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.main-menu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}
