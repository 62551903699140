.body-container {
	display: block;
  flex: 5;
  overflow-y: scroll;
  margin:5px;
  padding:1%;
  background-color:white;
  box-shadow:0px 0px 5px black;
}
.body-sub-container {
	display:flex;
	flex-direction: row;
	height: 100%;
}
.body-menu-container {
	border: 0px solid black;
	height: 100%;
	overflow-y: scroll;
	flex:1;
}
.body-outlet-container {
	flex:6;
	height:100%;
	overflow-y: scroll;
}
