.footer-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 0px solid green;
  border-radius: 3px;
	background-color:#dddddd;
	color: rgba(0, 0, 82, 1);
  box-shadow:0px 0px 5px black;
  overflow-y: visible;
  padding:1%;
  margin: 5px;
}
.footer-logo {
	flex: 1;
}
.footer-logo img {
	width: 50px;
}
.footer-menu {
	flex: 3;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	align-content: flex-start;
}
.footer-menu-button {
	display: block;
	border-top: 1px solid #96d1f8;
	padding: 5px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: white;
	font-size: 14px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	font-weight: bold;
	text-decoration: none;
	vertical-align: middle;
	margin: 1px 2px;
	cursor: pointer;
}
.footer-menu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.footer-menu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}
.footer-copyright {
	flex: 1;
}
