.header-logo-container{
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.header-logo{
	width: 200px;
}
.header-company-name{
	font-size: 1.3em;
	font-family: cursive;
	font-weight: bold;
}
