.aboutContainer{

}

.coachContainer{

}

.contactContainer{

}

.homeContainer{
  /* text-align: center; */
}

.presentContainer{

}

.centeredDiv {
  display: table;
  margin: 0 auto;
}

.sectionTitle {
  display: flex;
  align-self: start;
  font-weight: bold;
}

.sectionSubTitle {

}

.sectionListItem {
  display: flex;
  align-self: start;
  font-weight: normal;
}

.sectionText {
  display: flex;
  align-self: start;
  font-weight: normal;
}

.sectionContainer {
  margin: 5px;
}

.universityName {

}

.year {

}

.major {

}

.degree {

}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft10 {
  margin-left: 10px;
}
